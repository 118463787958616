var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', _vm._b({
          attrs: {
            "title": "Minimalism in Dentium"
          }
        }, 'sub-visual', {
          tabActive: _vm.tabActive
        }, false))];
      },
      proxy: true
    }])
  }, [_c('sub-depth-tab-wrap', [_c('v-container', [_c('chip-link-group', _vm._b({
    attrs: {
      "exact": "",
      "tabActive": _vm.code
    }
  }, 'chip-link-group', {
    chips: _vm.chips
  }, false))], 1)], 1), _c('v-container', [_c('router-view', _vm._b({
    attrs: {
      "name": "board",
      "skin": "gallery2",
      "hide-write-button": ""
    }
  }, 'router-view', {
    code: _vm.code
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }