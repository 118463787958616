<template>
    <div class="sub-depth-tab-wrap">
        <slot />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sub-depth-tab-wrap {
    margin: -40px 0 40px;
    > div {
        position: relative;
        z-index: 1;
    }
}
.sub-depth-tab-wrap + .sub-depth-tab-wrap {
    margin-top: -24px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-depth-tab-wrap {
        margin: -46px 0 80px;
    }
    .sub-depth-tab-wrap + .sub-depth-tab-wrap {
        margin-top: -46px;
    }
}
@media (min-width: 1024px) {
    .sub-depth-tab-wrap {
        margin: -86px 0 120px;
    }
    .sub-depth-tab-wrap + .sub-depth-tab-wrap {
        margin-top: -86px;
    }
}
@media (min-width: 1200px) {
}
</style>
