<template>
    <v-row v-bind="{ value }" align="center" justify="center" class="row--x-small">
        <v-col v-for="{ text, value, to } in chips" :key="value" cols="auto">
            <v-chip v-bind="{ ...$attrs, value, to }" rounded color="transparent" active-class="secondary white--text" :class="{ 'secondary white--text' : value == tabActive }">{{ text }}</v-chip>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },
        chips: { type: Array, default: () => [] },
        tabActive: { type: String, default: null },
    },
};
</script>

<style scoped>
.v-application .theme--light.v-chip{
    color: var(--default-light-text-color);
}
</style>
