var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual"
  }, [_vm.$slots['onlyTitle'] || _vm.onlyTitle ? _c('div', {
    staticClass: "sub-visual--only-title"
  }, [_vm.title ? _c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm._t("onlyTitle")], 2) : _c('v-container', [_vm.title ? _c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center",
    class: {
      'mb-0': _vm.$slots['searchField']
    }
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm.$slots['searchField'] ? _vm._t("searchField") : _vm._e(), _vm.$slots['customGnb'] ? _vm._t("customGnb") : _c('client-gnb', {
    attrs: {
      "className": "sub-tab-wrap",
      "tabActive": _vm.tabActive,
      "title": _vm.title
    }
  })], 2), _vm.$slots['subVisualImmersive'] ? _vm._t("subVisualImmersive") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }