<template>
    <div class="sub-visual">
        <div v-if="$slots['onlyTitle'] || onlyTitle" class="sub-visual--only-title">
            <div v-if="title" class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">{{ title }}</h2>
            </div>
            <slot name="onlyTitle" />
        </div>
        <v-container v-else>
            <div v-if="title" class="tit-wrap tit-wrap--dot justify-center" :class="{ 'mb-0': $slots['searchField'] }">
                <h2 class="tit">{{ title }}</h2>
            </div>
            <slot v-if="$slots['searchField']" name="searchField" />
            <slot v-if="$slots['customGnb']" name="customGnb" />
            <client-gnb v-else className="sub-tab-wrap" :tabActive="tabActive" :title="title"></client-gnb>
        </v-container>
        <!-- <div v-if="src" data-aos-once="true" data-aos="fade" class="sub-visual__img" :style="'background-image:url(' + src + ');'"></div> -->
        <slot v-if="$slots['subVisualImmersive']" name="subVisualImmersive" />
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default {
    props: {
        onlyTitle: { type: Boolean, default: false },
        title: { type: String, default: "" },
        src: { type: String, default: "" },
        tabActive: { type: String, default: "" },
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>

<style lang="scss" scoped>
.sub-visual {
    padding-top: 60px;
    ::v-deep {
        .tit-wrap {
            margin-bottom: 26px;
        }
        .tit {
            text-transform: uppercase;
        }
    }
    &__img {
        height: 180px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        padding-top: 80px;
        ::v-deep {
            .tit-wrap {
                margin-bottom: 30px;
            }
        }
        &__img {
            height: 240px;
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        padding-top: 120px;
        &__img {
            height: 300px;
        }
    }
}
@media (min-width: 1200px) {
}

.sub-visual--only-title {
    ::v-deep {
        .tit-wrap {
            margin-bottom: 0;
            & + .v-tabs {
                margin-top: 26px;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual--only-title {
        ::v-deep {
            .tit-wrap + .v-tabs {
                margin-top: 30px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
