<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Minimalism in Dentium" v-bind="{ tabActive }"> </sub-visual>
        </template>

        <sub-depth-tab-wrap>
            <v-container>
                <chip-link-group v-bind="{ chips }" exact :tabActive="code" />
            </v-container>
        </sub-depth-tab-wrap>

        <v-container>
            <router-view name="board" v-bind="{ code }" skin="gallery2" hide-write-button></router-view>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import ChipLinkGroup from "@/components/client/dumb/chip-link-group.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubDepthTabWrap,
        ChipLinkGroup,
    },
    computed: {
        code() {
            return `${this.code1}__${this.code2}`;
        },
        code1() {
            return this.$route.params.code1 || "digital";
        },
        code2() {
            return (
                this.$route.params.code2 ||
                {
                    digital: "digital-workflow-concept",
                    sinus: "simple-guide-line",
                    gbr: "simple-guide-line",
                    edentulous: "denture",
                    esthetic: "prosthesis-concept",
                }[this.code1]
            );
        },
        tabActive() {
            return {
                digital: "Digital",
                sinus: "Sinus",
                gbr: "GBR",
                edentulous: "Edentulous",
                esthetic: "Esthetic",
            }[this.code1];
        },
        chips() {
            return {
                digital: [
                    {
                        text: "Digital Workflow Concept",
                        value: "digital-workflow-concept",
                    },
                ],
                sinus: [
                    {
                        text: "Simple Guide Line",
                        value: "simple-guide-line",
                    },
                    {
                        text: "Complication Guide Line",
                        value: "complication-guide-line",
                    },
                ],
                gbr: [
                    {
                        text: "Simple Guide Line",
                        value: "simple-guide-line",
                    },
                ],
                edentulous: [
                    {
                        text: "Denture (Interim)",
                        value: "denture",
                    },
                    {
                        text: "All on X (Final)",
                        value: "all-on-x",
                    },
                ],
                esthetic: [
                    {
                        text: "Prosthesis Concept",
                        value: "prosthesis-concept",
                    },
                ],
            }[this.code1].map(({ text, value }) => ({ text, to: { path: `/minimalism/${this.code1}/${value}` } }));
        },
    },
    mounted() {
        this.syncRoute();
    },
    watch: {
        code() {
            this.syncRoute();
        },
        "$route.path"() {
            this.syncRoute();
        },
    },
    methods: {
        syncRoute() {
            const path = `/minimalism/${this.code1}/${this.code2}`;
            if (!this.$route.path.includes(path)) this.$router.replace({ path });
        },
    },
};
</script>

<style></style>
